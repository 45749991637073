import React, { useEffect, useState } from 'react';

const Services = () => {
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [desktopImageUrl, setDesktopImageUrl] = useState('');
    const [mobileImageUrl, setMobileImageUrl] = useState('');

    useEffect(() => {
        // Fetch the specific page data from the WordPress API
        fetch('https://development.ikf.in/flash/wp-json/wp/v2/pages/26/?_fields=title,acf')
            .then(response => response.json())
            .then(data => {
                setPage(data);
                // If the page has a valid `banner_image_desktop` ID, fetch the image URL
                if (data.acf && data.acf.banner_image_desktop) {
                    fetch(`https://development.ikf.in/flash/wp-json/wp/v2/media/${page.acf.banner_content}`)
                        .then(response => response.json())
                        .then(mediaData => {
                            setDesktopImageUrl(mediaData.source_url);
                        })
                        .catch(error => console.error("Error fetching banner image:", error));
                }
                
                if (data.acf && data.acf.banner_image_mobile) {
                    fetch(`https://development.ikf.in/flash/wp-json/wp/v2/media/${page.acf.banner_content}`)
                        .then(response => response.json())
                        .then(mediaData => {
                            setMobileImageUrl(mediaData.source_url);
                        })
                        .catch(error => console.error("Error fetching banner image:", error));
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching page:", error);
                setLoading(false);
            });
    }, []);

    if (loading) return <p>Loading page...</p>;
    if (!page) return <p>Page not found.</p>;

    return (
        <div>
            <h1>{page.title.rendered}</h1>
            {/* <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} /> */}

            {page.acf && (
                <div>

                <section className="section-wrap" id="bannersection">
                    <div className="section-container-full">
                        <div className="banner_images_desk">
                            <div className="banner_effect">
                                <div className="banner_patch"></div>
                                {desktopImageUrl && (
                                    <div
                                        className="banner_effect_inner"
                                        style={{
                                            backgroundImage: `url(${desktopImageUrl})`
                                        }}
                                    ></div>
                                )}
                                        
                                <div className="section-wrap" id="breadCrumb_title">
                                    <div className="section-container">
                                        <div className="section-row">
                                            <div className="section-column">
                                                <h1 className="banner_title">
                                                    <div dangerouslySetInnerHTML={{ __html: page.acf.banner_content }} />
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner_images_mobi">
                            <img src={desktopImageUrl} className="desktop_banner" />
                                <img src={mobileImageUrl} className="desktop_banner" />
                            </div>
                        </div>
                    </div>
                </section>
                </div>
            )}
        </div>
    );
};

export default Services;
